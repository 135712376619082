
import { Vue, Component } from "vue-property-decorator";
import UserAccess from "@/model/User/UserAccess";
import { mapGetters, mapActions } from 'vuex';
import { Role } from "@/interface/RoleInterface";
import Multiselect from "vue-multiselect";
import IbotechService from "@/services/ibotechService";

@Component({

  name: "QrBooking",
  components: { Multiselect },
  computed: {
    ...mapGetters(["getUserAccess", "getAllLocationsData"])
  },
  methods: {
    ...mapActions(["getAllLocations"])
  }
})
export default class QrBooking extends Vue {

  private getAllLocationsData!: Array<any>;
  readonly getUserAccess!: UserAccess;
  private getAllLocations!: Function;

  vehicleData: any = {};
  form = {
    plateNumber: "",
    id: "",
    notice: ""
  }

  isPlateValid: boolean = false;
  location: Array<any> = [];
  cityCode: string = "";
  identificationNumber: string = "";
  identificationLetter: string = "";

  created() {

    this.vehicleValidation(window.location.search.split('=')[1], 0);

    // USERACCESS
    // ----------
    if(!this.getUserAccess.AccessIbotech.VIEW.qrBooking) {
      this.$router.push({ name: 'NotFound' });
    } else {

      // Get - Set Location Data
      // -----------------------
      this.getAllLocations().then(() => {
      });

      // GET PlateNumber for Booking
      this.form.plateNumber = window.location.search.split('=')[1];

      this.cityCode = this.form.plateNumber.split("-")[0];
      this.identificationLetter = this.form.plateNumber.split("-")[1];
      this.identificationNumber = this.form.plateNumber.split("-")[2];
    }
  }

  mounted() {

    // SET LOCATIONS
    // -------------
    this.setLocations();

  }

  setLocations() {
    if(this.getAllLocationsData.length > 0) {
      this.location = this.getAllLocationsData.map(item => ({
        label: `${item.description} - ${item.locationType}`,
        value: item.id
      }));
    }
  }

  async vehicleValidation(plateNumber: any, delay: any) {

    // Ist es Kennzeichen
    const regex = /^[A-Z]{1,2}-[A-Z]{1,2}-\d{1,4}$/;
    this.isPlateValid = regex.test(plateNumber);

    const res = await IbotechService.getVehicle(plateNumber);

    if(res.status === 200) {

      if(!res.data || res.data == '') {
        this.$router.push({ name: 'NotFound' });
      }

      if(res.data.lastLocations.length == 2) {

        var delayTime:number = 0;

        if(delay) {
          delayTime = 100;
        }

        setTimeout(() => {
          this.$router.push({ name: 'RecordLimit' });
        }, delayTime);
      }

      this.vehicleData = res.data;
    } else {
      this.error();
    }

  }

  error() {
    this.$bvToast.toast( (this.$t('ibotech_vehicle.newVehicleForm.errorMessageText')).toString(), {
      title: (this.$t('ibotech_vehicle.newVehicleForm.errorMessageTitle')).toString(),
      autoHideDelay: 1500,
      variant: 'danger'
    })
  }

  successCreate() {

    // Success Message
    this.$bvToast.toast((this.$t('ibotech_vehicle.newVehicleForm.successMessageText')).toString(), {
      title: (this.$t('ibotech_vehicle.newVehicleForm.successMessageTitle')).toString(),
      autoHideDelay: 1500,
      variant: 'success'
    })

    // ToDo: Redirect auf VEhicle wurde erfolgreich für heute gescannt - 101

  }

  async submitVehicleRecord() {
    if(this.isFormValid) {
      const res = await IbotechService.putVehicleRecord(this.form);

      if(res.status == 202) {
        this.successCreate();

        this.form.id = "";
        this.form.notice = "";
        this.vehicleValidation(this.form.plateNumber, true);

      } else {

        this.$bvToast.toast((this.$t('ibotech_vehicle.newVehicleForm.errorMessageText')).toString(), {
          title: (this.$t('ibotech_vehicle.newVehicleForm.errorMessageTitle')).toString(),
          autoHideDelay: 1500,
          variant: 'danger'
        })
      }
    }
  }

  get isFormValid() {
    return Object.entries(this.form).every(([key, value]) => key === 'notice' || (value !== '' && value !== null));
  }

}
